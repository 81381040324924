import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from 'react-emotion';
import { PostFullHeader, PostFullTitle, NoImage, PostFull } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';
import config from '../website-config';

const PageTemplate = css`
  .site-main {
    background #fff;
    padding-bottom: 4vw;
  }
`;


const About: React.SFC = () => (
  <IndexLayout>
    <Helmet>
      <title>{`${config.seoTitle}`}</title>
      <meta name="description" content={config.seoDescription} />
    </Helmet>
    <Wrapper className={`${PageTemplate}`}>
      <header className={`${SiteHeader} ${outer}`}>
        <div className={`${inner}`}>
          <SiteNav />
        </div>
      </header>
      <main id="site-main" className={`site-main ${SiteMain} ${outer}`}>
        <article className={`${PostFull} post page ${NoImage}`}>
          <PostFullHeader>
            <PostFullTitle>What is Dev Meets Conf?</PostFullTitle>
          </PostFullHeader>

          <PostFullContent className="post-full-content">
            <div className="post-content">
              <p>
                Dev Meets Conf is meant to be a useful tool for those working as developers, PMs, QAs, UX, and
                others in the tech industry who want to find the best conferences happening this year.
              </p>
              <h2>Why did I create Dev Meets Conf?</h2><br />
              <p>
                I'm a software engineer and I was looking for an iOS or frontend conference to attend this year.
                I found the search to be difficult, as I was tediously looking at blog posts and trying to visit
                conference websites to collect the information I wanted to see. It was a frustrating experience
                and I still haven't decided which conference I'm going to. It should be much easier to find the
                conference you want to attend. I wanted search and filters / sorting based on conference
                popularity, cost, dates, languages, and more. So here we are.. Dev Meets Conf
              </p>
              <h2>Missing conferences, sponsors, and getting your conference featured</h2><br />
              <p>
                If a conference isn't on here, you would like to sponsor this site, or to add your conference to
                the featured conferences list for the visitors of this site to see, please reach out to me at
                devmeetsconf@gmail.com.
              </p>
              <p>
                Founded in 2018, Dev Meets Conf is just getting started. My goal is to help the those working in tech
                by giving them easy access to conference information in a simple, easy to use, and well
                designed web app.
              </p>
              <p>
                You can also follow Dev Meets Conf on Twitter, Facebook, and Instagram.
              </p>
              <p>
                Scott Walstead<br />
                Dev Meets Conf Founder
              </p>
            </div>
          </PostFullContent>
        </article>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default About;
